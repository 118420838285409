import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebookSquare, faReddit } from '@fortawesome/free-brands-svg-icons'
import {Helmet} from "react-helmet";

import './App.css';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function Index() {
  return <h1>My Fear Pic from Nightmares Fear Factory</h1>;
}

function AppRouter() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/:photo" component={Photo} />
        </Switch>
      </div>
    </Router>
  );
}

function handleImageError() {
  window.location.href = `https://www.nightmaresfearfactory.com/photo-not-found`
}

function Photo({ match }) {
  const url = `http://my.nightmaresfearfactory.com/${match.params.photo}`
  const title = 'My Fear Pic from Nightmares Fear Factory in Niagara Falls, Ontario'
  const description = 'My Fear Pic from Nightmares Fear Factory in Niagara Falls, Ontario, Canada'
  const image = `${url}.jpg`

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={ url } />

        <meta property="og:description" content={ description } />
        <meta property="og:site_name" content="Nightmares Fear Factory" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={ url } />
        <meta property="og:title" content={ title } />
        <meta property="og:image" content={ image } />

        <meta name="twitter:description" content={ description } />
        <meta name="twitter:card" content="photo" />
        <meta name="twitter:creator" content="@NightmaresFear" />
        <meta name="twitter:url" content={ url } />
        <meta name="twitter:title" content={ title } />
        <meta name="twitter:description" content={ description } />
        <meta name="twitter:image" content={ image } />
      </Helmet>
      <h1>My Fear Pic from Nightmares Fear Factory in Niagara Falls, Ontario</h1>
      <img src={image}
           onError={handleImageError}
           className="photo"
           alt="My Fear Pic from Nightmares Fear Factory in Niagara Falls, Ontario" />

      <h2>Share your FEAR Pic</h2>
      <ul class="sharing-ul">
        <li>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=My%20Fear%20Pic%20from%20Nightmares%20Fear%20Factory`}
             target="_blank"
             title="Share on Facebook"
             onclick={`window.open('https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=My%20Fear%20Pic%20from%20Nightmares%20Fear%20Factory`}
             class="share-buttons-facebook">
              <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
        </li>
        <li>
          <a href={`https://twitter.com/intent/tweet?source=&text=My%20Fear%20Pic%20from%20Nightmares%20Fear%20Factory:%20${encodeURIComponent(url)}&via=NightmaresFear`}
             target="_blank"
             title="Tweet"
             onclick={`window.open('https://twitter.com/intent/tweet?text=My%20Fear%20Pic%20from%20Nightmares%20Fear%20Factory:%20${encodeURIComponent(url)}`}
             class="share-buttons-twitter">
               <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li>
          <a href={`https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=My%20Fear%20Pic%20from%20Nightmares%20Fear%20Factory`}
             target="_blank"
             title="Submit to Reddit"
             onclick={`window.open('https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=My%20Fear%20Pic%20from%20Nightmares%20Fear%20Factory`}>
               <FontAwesomeIcon icon={faReddit} />
          </a>
        </li>
      </ul>

      <div class="download">
        <a download="nightmares-fear-pic.jpg" href={image}><FontAwesomeIcon icon={faDownload} /> Save Photo</a>
      </div>
    </div>
  )
}

export default AppRouter;
